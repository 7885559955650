import React from 'react';
import Layout from '../../components/layout';
import Questions, { QuestionsImages } from '../../components/Questions';

const Fadenlifting = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Fadenlifting"
			noSlider
			sidebarProps={{
				questions: (
					<Questions
						doctor={QuestionsImages.DR_KROKOWSKI}
						title="Fadenlifting"
						prefix="um"
					/>
				),
			}}
			{...props}
		>
			{/* <div
				className="md:float-right  rounded border bg-grey-lighter border-grey text-grey-darkest p-2 ml-2 mb-2"
				style={{ maxWidth: '300px' }}
			>
				<Questions
					doctor={QuestionsImages.DR_KROKOWSKI}
					title="Fadenlifting"
					prefix="um"
				/>
			</div> */}
			<p>
				Dieses Verfahren stellt eine weitere Alternative zur effizienten
				Behandlung von Falten und einem verbesserten Hautbild dar.
			</p>
			<p>
				Je nach verwendetem Faden kann durch die Reizung der Unterhaut
				eine Bindegewebsneubildung sowie ein Wiederaufbau des Collagen
				Stützgewebes erreicht werden.{' '}
			</p>
			<p>
				Oder man erreicht einen wirklichen leichteren Lifting Effekt
				durch das Einlegen von Fäden mit feinen Häkchen, die Wangen
				und/oder Halsregion anheben.
			</p>
			<p>
				Die Fäden werden über eine kleine, vorher betäubte Öffnung der
				Haut in die Unterhautschichten eingelegt. Andere Fäden mit einem
				stärkeren Effekt des Liftings werden mithilfe einer
				Gewebeanästhesie eingelegt.
			</p>
			<p>
				Erst die Entwicklung neuer Fadenmaterialien mit der Fähigkeit
				der Selbstauflösung ergaben eine neue Dimension der
				Gewebebehandlung mit einem lang anhaltendem Effekt.
			</p>
			<p>
				Fadenlifting eignet sich für Fältchen des Decollte Bereiches,
				Augen – oder Oberlippenregion oder dem Hals und der
				Wangenregion.
			</p>
			<p>
				Fadenlifting verbessert nicht nur die Elastizität Ihrer Haut
				sondern verbessert erkennbar das gesamte Hautbild. Sie sehen
				gesünder, vitaler, Ihre Haut jünger aus.
			</p>
			<p>
				Die Anzahl der einzulegenden Fädchen richtet sich nach dem zu
				straffenden Aral und Ihren Wünschen.{' '}
			</p>

			<p>
				Zusammenfassend bietet Ihnen das Fadenlifting:
				<ul>
					<li>
						einen natürlich aussehenden, sofort erkennbaren Effekt
					</li>
					<li>Ihre natürlichen Gesichtszüge bleiben erhalten</li>
					<li>langanhaltende Ergebnisse</li>
					<li>kein operativer Eingriff, keine Narkose</li>
					<li>ambulante Behandlung, geringe Ausfallzeit</li>
					<li>biokompatible, abbaubare Materialien</li>
				</ul>
			</p>
		</Layout>
	);
};

export default Fadenlifting;
